$(".form-list > .list-item").has(".error-text").addClass('error');

$(".form-list").prev().not(".box-consideration").not(".button-block").not(".button-block-wrap").not(".progressbar").addClass('form-list-prev');
$(".form-list-wrap").prev().not(".box-consideration").not(".button-block").not(".button-block-wrap").not(".progressbar").addClass('form-list-prev');
$(".form-list-wrap").prev().addClass('form-list-prev');
$(".button-block-wrap").has(".button-plus").prev().addClass('button-block-wrap-prev');



// 要素をコピー＆追加する関数
function addGradeJs() {
  let elements = document.getElementById("gradeFormCopy");
  let copied = elements.cloneNode(true);
  elements.after(copied);
}

// ボタンにイベントハンドラをセット
const addGradeBtn = document.getElementById("addGrade");
if (addGradeBtn != null) {
  addGradeBtn.addEventListener("click", addGradeJs, false);
}
