import Swiper, {
    Navigation,
    Pagination,
    Autoplay,
    EffectFade
} from 'swiper';

// TOP MV
const myDelay = 7000;
let timer;

// アニメーション切り替え
const switchAnimation = () => {
    clearTimeout(timer);
    let activeSlide = document.querySelectorAll('.mvSwiper .swiper-slide[class*=-active]');
    for (let i = 0; i < activeSlide.length; i++) {
        activeSlide[i].classList.remove('anm-finished');
        activeSlide[i].classList.add('anm-started');
    }
    timer = setTimeout(() => {
        for (let i = 0; i < activeSlide.length; i++) {
            activeSlide[i].classList.remove('anm-started');
            activeSlide[i].classList.add('anm-finished');
        }
    }, myDelay - 1000);
}

// アニメーション終了
const finishAnimation = () => {
    let activeSlide = document.querySelectorAll('.mvSwiper .swiper-slide.anm-started');
    for (let i = 0; i < activeSlide.length; i++) {
        activeSlide[i].classList.remove('anm-started');
        activeSlide[i].classList.add('anm-finished');
    }
}

const topMvSwiper = new Swiper('.mvSwiper', {
    modules: [Navigation, Pagination, Autoplay, EffectFade],
    followFinger: false,
    loopAdditionalSlides: 1,
    autoplay: { // 自動再生させる
        delay: myDelay, // 次のスライドに切り替わるまでの時間（ミリ秒）
        disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
        waitForTransition: false,
    },
    loop: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 2000,
    // speed: 0, // fadeの時は0にする
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return (
                '<div class="' +
                className +
                ' circle' +
                '"><div class="circle_inner">' +
                (Number(index) + 1) +
                '</div><svg viewBox="0 0 32 32" class="circle-svg"><circle class="circle01" cx="50%" cy="50%" r="16"></circle><circle class="circle02" cx="50%" cy="50%" r="16"></circle></svg></div>'
            )
        },
    },
    on: {
        slideChange: () => {
            finishAnimation();
        },
        slideChangeTransitionStart: () => {
            switchAnimation();
        },
    }
});

const pageMvSwiper = new Swiper('.pageSwiper', {
    modules: [Navigation, Pagination, Autoplay, EffectFade],
    slidesPerView: 1, // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    spaceBetween: 0, // スライド間の余白（px）
    centeredSlides: true, // アクティブなスライドを中央に配置する

    watchOverflow: true, //スライドが１枚の場合はコントロールを非表示
    autoplay: { // 自動再生させる
        delay: 7000, // 次のスライドに切り替わるまでの時間（ミリ秒）
        disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない

    },
    loop: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 0, // fadeの時は0にする
});

const mySwiper = new Swiper('.productSwiper', {
    modules: [Navigation, Pagination, Autoplay, EffectFade],
    followFinger: false,
    loopAdditionalSlides: 1,
    autoplay: {
        delay: 8000,
        waitForTransition: false,
    },
    loop: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 4000,
});