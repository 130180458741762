$(function () {
    $("header.header-common").load("/assets/include/header-common.html", function () {
        var pageURL = location.pathname;
        var exclusionEnURL = pageURL.replace('/en/', '/');
        var vanillaURL = exclusionEnURL.replace('/ch/', '/').replace("/cn/", "/");

        $('#jpLink').attr('href', vanillaURL);
        $('#enLink').attr('href', '/en' + vanillaURL);
        $('#cnLink').attr('href', '/ch' + vanillaURL);

        pageURLArr = pageURL.split('/');
        pageURLArrCategory = pageURLArr[1];
        $('.list-nav >.list-item >.accordion-title > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

        $('.list-nav >.list-item > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });
    });
    $("header.header-result").load("/assets/include/header-result.html", function () {
        var pageURL = location.pathname;
        var exclusionEnURL = pageURL.replace('/en/', '/');
        var vanillaURL = exclusionEnURL.replace('/ch/', '/').replace("/cn/", "/");

        $('#jpLink').attr('href', vanillaURL);
        $('#enLink').attr('href', '/en' + vanillaURL);
        $('#cnLink').attr('href', '/ch' + vanillaURL);

        pageURLArr = pageURL.split('/');
        pageURLArrCategory = pageURLArr[1];
        $('.list-nav >.list-item >.accordion-title > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

        $('.list-nav >.list-item > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });
    });
    $("footer.footer-common").load("/assets/include/footer-common.html");
    $(".contact").load("/assets/include/contact.html", function () {
        let dialogSdsModal01 = document.getElementById('sdsModal01'); // dialog（モーダルダイアログ）の宣言
        let btn_openSdsButton01 = document.getElementById('sdsButton01'); // open（開く）ボタンの宣言
        let btn_sdsButtonNo01 = document.getElementById('sdsButtonNo01'); // Noボタンの宣言
        let btn_sdsButtonClose01 = document.getElementById('sdsButtonClose01'); // close（閉じる）ボタンの宣言
        if ($('#sdsButton01').length > 0) {
            btn_openSdsButton01.addEventListener('click', function () {
                // 開くボタンをクリックした場合の処理
                dialogSdsModal01.showModal();
                $('body').addClass('active02');
                $('#contactMessage1').show();
                $('#contactMessage2').hide();
                $('#contactMessage2').next('.button-area').hide();
            }, false);
            btn_sdsButtonNo01.addEventListener('click', function () {
                $('#contactMessage1').hide();
                $('#contactMessage2').show();
                $('#contactMessage2').next('.button-area').show();
            }, false);
            dialogSdsModal01.addEventListener('click', function (e) {
                // 閉じるボタンをクリックした場合の処理
                if (!e.target.closest(".modal-block")) {
                    dialogSdsModal01.close();
                    $('body').removeClass('active02');
                }
            }, false);
            btn_sdsButtonClose01.addEventListener('click', function (e) {
                // 閉じるボタンをクリックした場合の処理
                    dialogSdsModal01.close();
                    $('body').removeClass('active02');
            }, false);
        }
    });
    $("header.header-english").load("/assets/include/header-english.html", function () {
        var pageURL = location.pathname;
        var exclusionEnURL = pageURL.replace('/en/', '/');
        var vanillaURL = exclusionEnURL.replace('/ch/', '/').replace("/cn/", "/");

        $('#jpLink').attr('href', vanillaURL);
        $('#enLink').attr('href', '/en' + vanillaURL);
        $('#cnLink').attr('href', '/ch' + vanillaURL);

        pageURLArr = pageURL.split('/');
        pageURLArrCategory = pageURLArr[2];
        $('.list-nav >.list-item >.accordion-title > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

        $('.list-nav >.list-item > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });
    });
    $("header.header-english-result").load("/assets/include/header-english-result.html", function () {
        var pageURL = location.pathname;
        var exclusionEnURL = pageURL.replace('/en/', '/');
        var vanillaURL = exclusionEnURL.replace('/ch/', '/').replace("/cn/", "/");

        $('#jpLink').attr('href', vanillaURL);
        $('#enLink').attr('href', '/en' + vanillaURL);
        $('#cnLink').attr('href', '/ch' + vanillaURL);

        pageURLArr = pageURL.split('/');
        pageURLArrCategory = pageURLArr[2];
        $('.list-nav >.list-item >.accordion-title > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

        $('.list-nav >.list-item > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });
    });
    $("footer.footer-english").load("/assets/include/footer-english.html");
    $(".contact-english").load("/assets/include/contact-english.html", function () {
        let dialogSdsModal01 = document.getElementById('sdsModal01'); // dialog（モーダルダイアログ）の宣言
        let btn_openSdsButton01 = document.getElementById('sdsButton01'); // open（開く）ボタンの宣言
        let btn_sdsButtonNo01 = document.getElementById('sdsButtonNo01'); // Noボタンの宣言
        let btn_sdsButtonClose01 = document.getElementById('sdsButtonClose01'); // close（閉じる）ボタンの宣言
        if ($('#sdsButton01').length > 0) {
            btn_openSdsButton01.addEventListener('click', function () {
                // 開くボタンをクリックした場合の処理
                dialogSdsModal01.showModal();
                $('body').addClass('active02');
                $('#contactMessage1').show();
                $('#contactMessage2').hide();
                $('#contactMessage2').next('.button-area').hide();
            }, false);
            btn_sdsButtonNo01.addEventListener('click', function () {
                $('#contactMessage1').hide();
                $('#contactMessage2').show();
                $('#contactMessage2').next('.button-area').show();
            }, false);
            dialogSdsModal01.addEventListener('click', function (e) {
                // 閉じるボタンをクリックした場合の処理
                if (!e.target.closest(".modal-block")) {
                    dialogSdsModal01.close();
                    $('body').removeClass('active02');
                }
            }, false);
            btn_sdsButtonClose01.addEventListener('click', function (e) {
                // 閉じるボタンをクリックした場合の処理
                    dialogSdsModal01.close();
                    $('body').removeClass('active02');
            }, false);
        }
    });
    $("header.header-chinese").load("/assets/include/header-chinese.html", function () {
        var pageURL = location.pathname;
        var exclusionEnURL = pageURL.replace('/en/', '/');
        var vanillaURL = exclusionEnURL.replace('/ch/', '/').replace("/cn/", "/");

        $('#jpLink').attr('href', vanillaURL);
        $('#enLink').attr('href', '/en' + vanillaURL);
        $('#cnLink').attr('href', '/ch' + vanillaURL);

        pageURLArr = pageURL.split('/');
        pageURLArrCategory = pageURLArr[2];
        $('.list-nav >.list-item >.accordion-title > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

        $('.list-nav >.list-item > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

    });
    $("header.header-chinese-result").load("/assets/include/header-chinese-result.html", function () {
        var pageURL = location.pathname;
        var exclusionEnURL = pageURL.replace('/en/', '/');
        var vanillaURL = exclusionEnURL.replace('/ch/', '/').replace("/cn/", "/");

        $('#jpLink').attr('href', vanillaURL);
        $('#enLink').attr('href', '/en' + vanillaURL);
        $('#cnLink').attr('href', '/ch' + vanillaURL);

        pageURLArr = pageURL.split('/');
        pageURLArrCategory = pageURLArr[2];
        $('.list-nav >.list-item >.accordion-title > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

        $('.list-nav >.list-item > a').each(function (i, v) {
            var selfhref = $(v).attr('href'),
                hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
                hrefArrCategory = hrefArr[2]; //href属性の第1階層を取得

            //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
            if (pageURLArrCategory === hrefArrCategory) {
                $(v).parents('.list-item').addClass('active');
            }

        });

    });
    $("footer.footer-chinese").load("/assets/include/footer-chinese.html");
    $(".contact-chinese").load("/assets/include/contact-chinese.html", function () {
        let dialogSdsModal01 = document.getElementById('sdsModal01'); // dialog（モーダルダイアログ）の宣言
        let btn_openSdsButton01 = document.getElementById('sdsButton01'); // open（開く）ボタンの宣言
        let btn_sdsButtonNo01 = document.getElementById('sdsButtonNo01'); // Noボタンの宣言
        let btn_sdsButtonClose01 = document.getElementById('sdsButtonClose01'); // close（閉じる）ボタンの宣言
        if ($('#sdsButton01').length > 0) {
            btn_openSdsButton01.addEventListener('click', function () {
                // 開くボタンをクリックした場合の処理
                dialogSdsModal01.showModal();
                $('body').addClass('active02');
                $('#contactMessage1').show();
                $('#contactMessage2').hide();
                $('#contactMessage2').next('.button-area').hide();
            }, false);
            btn_sdsButtonNo01.addEventListener('click', function () {
                $('#contactMessage1').hide();
                $('#contactMessage2').show();
                $('#contactMessage2').next('.button-area').show();
            }, false);
            dialogSdsModal01.addEventListener('click', function (e) {
                // 閉じるボタンをクリックした場合の処理
                if (!e.target.closest(".modal-block")) {
                    dialogSdsModal01.close();
                    $('body').removeClass('active02');
                }
            }, false);
            btn_sdsButtonClose01.addEventListener('click', function (e) {
                // 閉じるボタンをクリックした場合の処理
                    dialogSdsModal01.close();
                    $('body').removeClass('active02');
            }, false);
        }
    });

    $('head').append(`<!-- Google Tag Manager -->
    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TVP745H');
    </script>
    <!-- End Google Tag Manager -->`);
    $('body').append(`<!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TVP745H" height="0" width="0"
        style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->`);
    $('head').after(`<script>
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date();
      a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
  
    ga('create', 'UA-72667710-1', 'auto', {
      'allowLinker': true
    });
    ga('require', 'linker');
    ga('linker:autoLink', ['customer.mep-system.net']);
    ga('send', 'pageview');
  </script>`);
    $('head').after(`<!--listfinder start-->
    <script type="text/javascript">
      var _trackingid = 'LFT-10930-1';
    
      (function () {
        var lft = document.createElement('script');
        lft.type = 'text/javascript';
        lft.async = true;
        lft.src = ('https:' == document.location.protocol ? 'https:' : 'http:') + '//track.list-finder.jp/js/ja/track.js';
        var snode = document.getElementsByTagName('script')[0];
        snode.parentNode.insertBefore(lft, snode);
      })();
    </script>
    <!--listfinder end-->`);
});