  // ハンバーガーメニュー時　bodyを固定
  $('label[for="drawer-check"]').on('click', function () {
    if ($('input#drawer-check').prop("checked") != true) {
      $('body').addClass('active');
    } else {
      $('body').removeClass('active');
    }
  });

  // PCサイズ時　body固定　ハンバーガーメニュー解除
  $(window).resize(function () {
    let w = $(window).width();
    if (w < 1024) {
      $('body').removeClass('active');
      $('header input#drawer-check').removeAttr("checked").prop("checked", false).change();
      $('header input.toggle').removeAttr("checked").prop("checked", false).change();
    }
  });
