const setVw = function () {
  const vw = document.documentElement.clientWidth / 100;
  document.documentElement.style.setProperty("--vw", `${vw}px`);
};
window.addEventListener("DOMContentLoaded", setVw);
window.addEventListener("resize", setVw);

// ボタン系
let isNeedInit = true;

function initPage() {
  // 初期化フラグが立っている場合にのみ実行します
  if (!isNeedInit) {
    return;
  }
  // 初期化フラグを折ります
  isNeedInit = false;

  $(".button-search").not('[target="_blank"],[href]').click(function () {
      $(this).parents(".button-search-wrap").addClass("active");
      $("body").addClass("active02");
      $(this).parents(".table-common td").addClass("active");
      $(this).parents('[class^="page-block-"],[class^="top-block"]').addClass("active");
      $(this).parents('[class^="page-block-"],[class^="top-block"]').removeClass("fadeUpTrigger");
      $(this).parents('[class^="page-block-"],[class^="top-block"]').removeClass("fadeUp");

      $(this).parents(".button-search-wrap").append('<div class="overlay"></div>');
    });

  $(".button-search-wrap .button-area .button-small01.close").click(function () {
    $(this).parents(".button-search-wrap").removeClass("active");
    $(this).parents(".table-common td").removeClass("active");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').removeClass("active");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').addClass("fadeUpTrigger");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').addClass("fadeUp");
    $("body").removeClass("active02");
    $(".overlay").remove();
    return false;
  });

  $(".button-map, .button-map-wrap .button-small01").click(function () {
    $(this).parents(".button-map-wrap").addClass("active");
    $("body").addClass("active02");
    $(this).parents(".table-common td").addClass("active");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').addClass("active");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').removeClass("fadeUpTrigger");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').removeClass("fadeUp");

    $(this).parents(".button-map-wrap").append('<div class="overlay"></div>');
  });

  $(".button-map-wrap .button-area .button-small01.close").click(function () {
    $(this).parents(".button-map-wrap").removeClass("active");
    $(this).parents(".table-common td").removeClass("active");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').removeClass("active");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').addClass("fadeUpTrigger");
    $(this).parents('[class^="page-block-"],[class^="top-block"]').addClass("fadeUp");
    $("body").removeClass("active02");
    $(".overlay").remove();
    return false;
  });

  $(document).on("click", ".overlay", function () {
    $(".button-search-wrap").removeClass("active");
    $(".button-movie-wrap").removeClass("active");
    $(".button-map-wrap").removeClass("active");
    $("body").removeClass("active02");
    $(".overlay").remove();
    $('[class^="page-block-"],[class^="top-block"]').removeClass("active");
    $('[class^="page-block-"],[class^="top-block"]').addClass("fadeUpTrigger");
    $('[class^="page-block-"],[class^="top-block"]').addClass("fadeUp");
    return false;
  });

  // SDS
  let dialogSdsModal02 = document.getElementById("sdsModal02"); // dialog（モーダルダイアログ）の宣言
  let btn_openSdsButton02 = document.getElementById("sdsButton02"); // open（開く）ボタンの宣言
  let btn_sdsButtonNo02 = document.getElementById("sdsButtonNo02"); // Noボタンの宣言
  let btn_sdsButtonClose02 = document.getElementById("sdsButtonClose02"); // close（閉じる）ボタンの宣言
  if ($("#sdsButton02").length > 0) {
    btn_openSdsButton02.addEventListener(
      "click",
      function () {
        // 開くボタンをクリックした場合の処理
        dialogSdsModal02.showModal();
        $("body").addClass("active02");
        $("#contactMessage3").show();
        $("#contactMessage4").hide();
        $("#contactMessage4").next(".button-area").hide();
      },
      false
    );
    btn_sdsButtonNo02.addEventListener(
      "click",
      function () {
        $("#contactMessage3").hide();
        $("#contactMessage4").show();
        $("#contactMessage4").next(".button-area").show();
      },
      false
    );
    dialogSdsModal02.addEventListener(
      "click",
      function (e) {
        // 閉じるボタンをクリックした場合の処理
        if (!e.target.closest(".modal-block")) {
          dialogSdsModal02.close();
          $("body").removeClass("active02");
        }
      },
      false
    );
    btn_sdsButtonClose02.addEventListener(
      "click",
      function (e) {
        // 閉じるボタンをクリックした場合の処理
        dialogSdsModal02.close();
        $("body").removeClass("active02");
      },
      false
    );
  }

  $("button.button-small01.print").click(function () {
    var printPage = $(this).closest(".button-map-area").html();
    $("body").append('<div id="print"></div>');
    $("#print").append(printPage);
    $("body > :not(#print)").addClass("print-off");
    window.print();
    $("#print").remove();
    $(".print-off").removeClass("print-off");
  });
}

// ページ離脱の際に初期化フラグを立て直します
window.addEventListener("pagehide", () => {
  isNeedInit = true;
});

// ページ再訪問時に初期化を求めます
window.addEventListener("pageshow", initPage);

// 最初の初期化を実行します
// 必要に応じてDOMContentLoaded等と連携してください
initPage();
